<template>
  <div>
    <!-- DESKTOP -->
    <div class="desktop pl-8 pr-8 ml-3 mr-3">
      <v-row justify="center">
        <v-col class="pl-0 pr-0 text-left" cols="12">
          <h1 class="action-today__title">Solicitudes</h1>
        </v-col>
        <v-col cols="12">
          <v-row align="center">
            <template v-for="data in listActionToday">
              <v-col
                class="pl-0 pr-6 action-today__col-card"
                :key="data.id"
                cols="2"
              >
                <v-card outlined elevation="0" class="action-today__card ">
                  <v-card-title>
                    <v-layout justify-center>
                      <img :src="data.icon" />
                    </v-layout>
                  </v-card-title>
                  <v-card-text style="height: 170px" class="text-left">
                    <p class="text-my-actions">{{ data.name }}</p>
                    <vue-show-more-text
                      class="pb-0 pt-0 pl-0 pr-0 description-action"
                      :text="data.description"
                      :lines="3"
                      more-text="Ver más"
                      less-text="Ver menos"
                      additional-content-css="font-size:16px;"
                      additional-content-expanded-css="font-size:16px;"
                      additional-anchor-css="font-size: 16px;"
                    />
                  </v-card-text>
                  <v-card-actions class="pt-0 mt-0 mb-3">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        @click="App(data)"
                        block
                        rounded
                        style="color: #fff"
                        :style="{
                          'background-color': hover ? '#324CA1' : '#466BE3',
                        }"
                      >
                        SELECCIONAR
                      </v-btn>
                    </v-hover>
                  </v-card-actions>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- MOBILE -->
    <div class="container mobile-action">
      <div class="container-greeting">
        <div class="mt-3">
          <p class="request__greeting text-title-1">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img
                width="15"
                class="mt-1"
                src="../../assets/icon/navigate_before.png"
              />
            </v-btn>
            {{ name }}, acá puede realizar sus solicitudes
          </p>
          <p class="greeting-requests pl-9">¿Qué necesita hacer hoy?</p>
        </div>
      </div>

      <div class="container-card v-step-mobile-28">
        <template v-for="data in listActionToday">
          <v-card
            v-if="data.mobileVersion"
            :key="data.id"
            class="requests-card pb-1 pt-1"
          >
            <v-col
              style="text-align: initial"
              class="pl-2 pr-0"
              :key="data.id"
              cols="2"
            >
              <v-img width="60" :src="data.icon"></v-img>
            </v-col>
            <v-col :key="data.id" cols="7" class="d-flex align-center">
              <p class="text-today-sm pb-0 mb-0 pt-1">{{ data.name }}</p>
            </v-col>
            <v-col :key="data.id" cols="2" class="d-flex align-center">
              <v-btn small rounded dark @click="App(data)" class="btn-main mt-1"
                >ver</v-btn
              >
            </v-col>
          </v-card>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import vueShowMoreText from "vue-show-more-text";

export default {
  components: {
    vueShowMoreText,
  },
  data() {
    return {
      origin: window.location.origin,
      loadingAll: false,
      listActionToday: [
        {
          id: 1,
          icon: origin + "/icon/solicitudes-4v2.svg",
          name: "Permisos",
          url: "https://colaborador.brm.com.co/WEBKACTUS/",
          externarUrl: true,
          description: "Solicite sus permisos en cualquier momento.",
          mobileVersion: true,
        },
        {
          id: 2,
          icon: origin + "/icon/solicitudes-1v2.svg",
          name: "Incapacidades",
          url: "https://colaborador.brm.com.co/WEBKACTUS/",
          externarUrl: true,
          description: "Reporte sus incapacidades.",
          mobileVersion: true,
        },
        {
          id: 3,
          icon: origin + "/icon/solicitudes-2v2.svg",
          name: "Vacaciones",
          url: "https://colaborador.brm.com.co/WEBKACTUS/",
          externarUrl: true,
          description: "Solicite con antelación sus períodos vacacionales.",
          mobileVersion: true,
        },
        // {
        //   id: 5,
        //   icon: origin + "/icon/solicitudes-5v2.svg",
        //   name: "Renuncias voluntarias",
        //   url: "reportRetirement",
        //   externarUrl: false,
        //   description:
        //     "Verifique los procesos de renuncia de sus colaboradores.",
        //   mobileVersion: false,
        // },
        {
          id: 4,
          icon: origin + "/icon/solicitudes-3v2.svg",
          name: "Certificados",
          url: "https://colaborador.brm.com.co/WEBKACTUS/",
          externarUrl: true,
          description: "Descargue todo tipo de certificados laborales.",
          mobileVersion: true,
        },
        // {
        //   id: 5,
        //   icon: origin + "/icon/horas.svg",
        //   name: "Horas extras",
        //   url: "extraHours",
        //   externarUrl: false,
        //   description: "Solicite aquí sus horas extras a su jefe inmediato.",
        //   mobileVersion: false,
        // },
      ],
      infoToken: {
        document: "",
      },
      name: "",
    };
  },
  methods: {
    App(item) {
      if (item.externarUrl) window.open(item.url, "_blank");
      else this.$router.push({ name: item.url });
    },
    getToken() {
      let token = localStorage.getItem("token");
      let div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        let nameList = this.infoToken.fullName.split(" ");
        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = this.toTitleCase(nameList[0]);
          } else {
            this.name = this.toTitleCase(nameList[1]);
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
  },
  created() {
    this.getToken();
  },
};
</script>
<style lang="less" scoped>
@import "./../../assets/css/main.less";
// .navidad-style{
//   // background-color: red;
//   background-image: url(../../assets/img/navidad/border-navidad3.png);
//   background-repeat: round;
//   background-position-y: -10px;
// }
.navidad-text{
  display: flex;
}
.text-my-actions {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px !important;
  line-height: 28px;
  font-size: 20px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: #353535;
}
.text-my-actions-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 37px;
  color: #353535;
}

.container {
  padding-bottom: 50px;
}

.container-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.container-text {
  width: 50%;
}

.requests-card {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  border-radius: 10px !important;
  border: 1px solid #466be3 !important;
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
}

.title-requests {
  font-family: "RobotoRegular";
  text-align: left;
  font-size: 20px;
}

.text-requests {
  font-family: "RobotoMedium";
  text-align: left;
}

.select-bottom {
  width: 100%;
  font-weight: bold;
  font-size: 11px !important;
  padding: 0 !important;
  font-family: "RobotoRegular";
  background-color: #466be3 !important;
  color: #ffffff !important;
  border-radius: 50px;
  box-shadow: 0px 2px 8px rgba(50, 76, 161, 0.5),
    0px 4px 24px rgba(50, 76, 161, 0.3);
}

.container-greeting {
  display: flex;
}

.navigate-before {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: flex-start;
}

.request__greeting {
  color: #466be3;
  font-family: "RobotoRegular";
  margin: 0 !important;
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  text-align: left;
}

.greeting-requests {
  font-family: "RobotoRegular";
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin: 0 !important;
  text-align: left;
  color: #626262;
}

.container-image-before {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.title {
  display: none;
}

.description-action {
  color: #626262;
  font-family: "RobotoRegular";
  text-align: center;
}
.action-today__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.action-today__card {
  border-radius: 15px !important;
  width: 280px;
}

.action-today__card:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

.action-today__col-card {
  max-width: 280px !important;
}

@media only screen and (min-width: 260px) {
  .mobile-action {
    display: block;
  }

  .desktop {
    display: none;
  }
  .text-today-sm {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 320px) {
  .container-image-before {
    width: 30%;
  }

  .container-image {
    padding: 10px;
  }
  .text-today-sm {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 425px) {
  .container-image-before {
    width: 20%;
  }

  .requests-card {
    width: 85%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .text-today-sm {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 550px) {
  .requests-card {
    width: 70%;
  }

  .text-today-sm {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 600px) {
  .requests-card {
    width: 60%;
  }
  .text-today-sm {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 768px) {
  .container-greeting {
    display: block;
  }

  .title {
    display: block;
    text-align: left;
    width: 100%;
    margin-bottom: 0;
    font-family: "RobotoRegular";
    font-weight: 300;
    font-size: 30px;
    line-height: 37px;
    color: #353535;
  }

  .container-card {
    width: 93%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .requests-card {
    margin: auto;
    width: 47%;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .banner-first-title {
    margin-bottom: 0;
    color: #ffffff;
    font-family: "RobotoMedium";
    font-weight: 300;
    font-size: 20px;
  }

  .banner-second-title {
    margin-bottom: 0;
    color: #ffffff;
    font-family: "RobotoRegular";
    font-weight: 300;
    font-size: 30px;
  }

  .title-requests {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .text-requests {
    height: 110px;
  }

  .text-today-sm {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 1024px) {
  .mobile-action {
    display: none;
  }

  .desktop {
    display: block;
  }

  .container {
    max-width: 100%;
    padding-top: 30px;
  }

  // .banner {
  //   height: 200px;
  // }

  .container-card {
    width: 90%;
  }

  .requests-card {
    width: 23%;
    height: 360px;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
  }

  .container-image {
    width: 100%;
    height: 130px;
  }

  .container-text {
    width: 100%;
  }

  .title-requests {
    font-size: 19px;
    height: 30px;
  }

  .text-requests {
    height: 110px;
  }

  .text-today-sm {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #466be3;
  }

  // .text-my-actions {
  //   font-size: 14px;
  // }
}

// @media only screen and (min-width: 1200px) {
//   .text-my-actions {
//     font-size: 17.5px;
//   }
// }
</style>
